import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import SubHero from "../components/SubHero"
import JobCarousel from "../components/JobCarousel"
import ImageGrid from "../components/ImageGrid"
import ImageCopy from "../components/ImageCopy"
import ThreeColumn from "../components/ThreeColumn"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      wpPage(databaseId: { eq: 102 }) {
        databaseId
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphType
          canonical
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        Pages_CF {
          heroSubText
          heroText
          heroVideo
          heroImage {
            sourceUrl
          }
          introTitle
          introSubTitle
          introContent
          subHeroTitle
          subHeroSubTitle
          subHeroImage {
            sourceUrl
          }
          subHeroContent
          subHeroButtonUrl
          subHeroButtonLabel
          gridImages {
            title
            altText
            gridArea
            imagePath {
              sourceUrl
            }
          }
          threeColumnHeaderText
          backgroundColor
          threeColumnCardsCopy {
            content
            link
            linkLabel
            title
            image {
              sourceUrl
            }
          }
          cardCollectionHp {
            buttonLabel
            buttonUrl
            content
            featured
            title
            linkUrl
            linkLabel
            image {
              sourceUrl
            }
          }
          cardCollectionHp2 {
            buttonLabel
            buttonUrl
            content
            featured
            title
            linkUrl
            linkLabel
            image {
              sourceUrl
            }
          }
          cardHeaderText2
          cardHeaderText
          ctaHeader
          ctaButtonLabel
          ctaButtonUrl
          ctaLinkLabel
          ctaLinkUrl
          ctaBackgroundImage {
            sourceUrl
          }
        }
      }
      jobs: allWpJobPost {
        nodes {
          modified
          jobPostings {
            url
            typeOfPosition
            techStack
            starttime
            roleCategory
            memberstackId
            jobTitle
            jobDescription
            industry
            featured
            expirationDate
            endtime
            companyUrl
            companyState
            companyName
            companyCity
          }
        }
      }
      profiles: allWpCompanyProfile {
        nodes {
          slug
          title
          id
          companyProfiles {
            memberstackId
          }
        }
      }
      stories: allWpFeaturedStory {
        nodes {
          title
          databaseId
          featuredStories {
            storyImage
            storyHeader
            storyDescription
            storyFeatured
          }
        }
      }
    }
  `)

  const IndexPageData = data.wpPage.Pages_CF
  const seoData = data.wpPage.seo
  const featuredImage = data.wpPage.featuredImage
  const featuredStories = data.stories.nodes
  const profiles = data.profiles.nodes
  const [shuffled, setShuffledArray] = useState([])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Home",
  })

  useEffect(() => {
    setShuffledArray(shuffleStories(featuredStoryListings))
  }, [])

  function shuffleStories(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i))

      var temp = sourceArray[j]
      sourceArray[j] = sourceArray[i]
      sourceArray[i] = temp
    }
    return sourceArray
  }

  let featuredStoryListings = []
  let shuffledStories = []
  featuredStories.forEach((story) => {
    if (story.featuredStories.storyFeatured === "true") {
      featuredStoryListings.push({
        buttonLabel: "",
        buttonUrl: "",
        content: story.featuredStories.storyDescription,
        featured: story.featuredStories.storyFeatured,
        title: story.featuredStories.storyHeader,
        linkUrl: "/recruit-with-us/",
        linkLabel: "Want to be Featured?",
        image: {
          sourceUrl: story.featuredStories.storyImage,
        },
      })
    }
  })

  return (
    <Layout bodyClass={`page page-id-${data.wpPage.databaseId}`}>
      <Seo title={seoData.title} description={seoData.metaDesc} uri={seoData.canonical} socialImage={featuredImage} />
      <Hero hero={IndexPageData} />
      <ThreeColumn columns={IndexPageData.threeColumnCardsCopy} title={IndexPageData.threeColumnHeaderText} background={IndexPageData.backgroundColor} />
      <div className="container">
        <ImageCopy cards={shuffled.slice(0, 3)} title={IndexPageData.cardHeaderText} />
      </div>
      <div className="container">
        <ImageCopy cards={IndexPageData.cardCollectionHp2} title={IndexPageData.cardHeaderText2} flip={true} />
      </div>
      <SubHero hero={IndexPageData} />
      <ImageGrid gallery={IndexPageData.gridImages} />
    </Layout>
  )
}

export default IndexPage
