import React from "react"
import Slider from "react-slick"
import {INDUSTRIES} from "../constants/industries";
import {CATEGORIES} from "../constants/categories";

const JobCarousel = ({ jobs, title, getIndustry, profiles }) => {
  var settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: "150px",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  }
  const positionTypes = INDUSTRIES;

  const jobCategories = CATEGORIES;

  const categoryBadgeFactory = (categories) => badgeFactory(categories, jobCategories)
  const industryBadgeFactory = (categories) => badgeFactory(categories, positionTypes)

  const badgeFactory = (categories, categoryMap) => {
    return categories?.map((c) => {
      const { categoryClass, displayName } = categoryMap[c] || {}

      return (
        <span key={displayName} className={categoryClass}>
          {displayName}
        </span>
      )
    })
  }

  const jobListings = []

  profiles.forEach((profile) => {
    for (let job in jobs) {
      if (profile.companyProfiles.memberstackId === jobs[job].jobPostings.memberstackId) {
        jobs[job].jobPostings.slug = profile.slug
        jobListings.push(jobs[job])
      }
    }
  })
  jobListings.sort(function (a, b) {
    let aDate = new Date(a.modified)
    let bDate = new Date(b.modified)
    return bDate - aDate
  })

  return (
    <div className="job-carousel">
      <div className="job-carousel__header">
        <h2>{title}</h2>
      </div>
      <Slider className="job-carousel__slider" {...settings}>
        {jobListings.slice(0, 6).map((el, idx) => {
          return (
            <div className="job-carousel-item" key={idx}>
              <div className="card job-card job-card--featured">
                <div className="job-card__left">
                  <div className="job-card__left__heading">
                    <h3>{el.jobPostings.jobTitle}</h3>
                    <h4>at {el.jobPostings.companyName}</h4>
                  </div>
                  <p>{el.jobPostings.jobDescription}</p>
                  <div className="job-card__left__tags">
                    {el.jobPostings.featured && <span className="tag tag--gold tag--gold--featured">Featured</span>}
                    {industryBadgeFactory(el.jobPostings.industry?.split(",").map((ind) => ind))}
                    {categoryBadgeFactory(JSON.parse(el.jobPostings.roleCategory)?.map((rc) => rc.label))}
                  </div>
                  <div className="job-card__left__date">
                    <span>Posted {new Date(el.modified).toDateString()}</span>
                  </div>
                </div>
                <div className="job-card__right">
                  <div className="job-card__right__details">
                    {el.jobPostings.companyName && (
                      <h5>
                        <i className="fas fa-building"></i> {el.jobPostings.companyName}
                      </h5>
                    )}
                    {el.jobPostings.companyCity && el.jobPostings.companyState && (
                      <h5>
                        <i className="fas fa-map-marker-alt"></i> {el.jobPostings.companyCity}, {el.jobPostings.companyState}
                      </h5>
                    )}
                    {el.jobPostings.typeOfPosition && (
                      <h5>
                        <i className="fas fa-calendar-alt"></i> {el.jobPostings.typeOfPosition}
                      </h5>
                    )}
                  </div>
                  <div className="job-card__right__cta">
                    <a className="button button--external button--primary" href={el.jobPostings.url} target="_blank">
                      Apply For Jobs
                    </a>
                    <a className="link" href={`/general/company-profile/${el.jobPostings.slug}`}>
                      View Company Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default JobCarousel
